import gql from 'graphql-tag';

import { IS_PREVIEW } from '@src/typescriptGlobals/constants';
import { GQL_PAGE_ID_TYPES } from '@src/typescriptGlobals/contentful.types';

export const GQL_FETCH_CONTACT_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.CONTACT_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
    }
  }
`;

// =============================================
//              SITE MAPS QUERY
// =============================================
export const GQL_FETCH_LLMS_DATA = `
  query ($preview: Boolean!) {
    posts: postCollection(
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_some: ["vh"], id_contains_none: ["translation"] } } }
    ) {
      items {
        slug
        languageCode
        sys {
          publishedAt
        }
        seoMetadata {
          title
          description
        }
      }
    }
    pages: pageCollection(
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_some: ["vh"], id_contains_none: ["translation"] } } }
    ) {
      items {
        slug
        languageCode
        sys {
          publishedAt
        }
        seoMetadata {
          title
          description
        }
      }
    }
  }
`;

export const GQL_FETCH_LANDING_PAGE_METADATA = `
  query($pageId: String!) {
    page(id: $pageId, preview: ${IS_PREVIEW}) {
      seoMetadata {
        title
        description
        previewImage {
          url
        }
        index
        follow
        canonical
      }
    }
  }
`;

// =============================================
//              BLOG QUERIES
// =============================================

export const GQL_GET_POST_METADATA_ENTRY = `
  query ($slug: String!) {
    postCollection(
      where: { slug: $slug, contentfulMetadata: {tags: {id_contains_some: ["vh"]}} }
      limit: 1
      preview: ${IS_PREVIEW}
    ) {
      items {
        seoMetadata {
          ... on SeoMetaTags {
            title
            description
            previewImage {
              url
            }
            index
            follow
            canonical
          }
        }
      }
    }
  }
`;

export const GQL_GET_POST_ENTRY = `
  query ($slug: String!) {
    postCollection(
      where: { slug: $slug, contentfulMetadata: {tags: {id_contains_some: ["vh"]}} }
      limit: 1
      preview: ${IS_PREVIEW}
    ) {
      items {
        slug
        title
        categoriesCollection(limit: 1) {
          items {
            title
            slug
          }
        }
        thumbnail {
          url
        }
        excerpt
        sys {
          publishedAt
          firstPublishedAt
        }
        body {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                url
                title
                width
                height
                contentType
                description
              }
            }
            entries {
              __typename
              inline {
                sys {
                  id
                }
                __typename
                ... on Image {
                  title
                  alignment
                  width
                  url
                  media {
                    url
                    height
                    width
                    description
                  }
                }
                ... on BlogComponent {
                  componentType
                  mergedCellTable
                }
              }
            }
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on TextEditor {
                  tinyMce
                }
                ... on Button {
                  text
                  url
                  type
                  target
                }
                ... on Image {
                  media {
                    url
                    width
                    height
                    description
                  }
                  alignment
                  width
                  url
                  bodyCopy {
                    json
                  }
                }
                ... on Video {
                  type
                  url
                  description
                }
                ... on BlogComponent {
                  componentType
                  textBoxColor
                  mergedCellTable
                  bodyCopy {
                    json
                    links {
                      entries {
                        block {
                          sys {
                            id
                          }
                          ... on Image {
                            alignment
                            width
                            url
                            media {
                              url
                              description
                              width
                              height
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on DuplexComponent {
                  reversed
                  title
                  bodyCopy {
                    json
                  }
                  mediaPrimary {
                    url
                    width
                    height
                    description
                  }
                }
                ... on Grid {
                  sys {
                    id
                  }
                  carousel
                  scrollTo
                  title
                  bodyCopy {
                          json
                        }
                  topicCollection {
                    items {
                      ... on Card {
                        mediaPrimary {
                          width
                          height
                          url
                          description
                        }
                        mediaSecondary {
                          width
                          height
                          url
                          description
                        }
                        title
                        slug
                        bodyCopy {
                          json
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        datePublished
        featuredImage {
          url
          width
          height
          title
          description
          contentType
        }
        languageCode
        translationsCollection(limit: 10) {
          items {
            title
            slug
            languageCode
          }
        }
        linkedFrom {
          postCollection(limit: 10) {
            items {
              languageCode
              title
              slug
              translationsCollection(limit: 10) {
                items {
                  languageCode
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_POST_ENTRY_SLUGS = `
  query {
    postCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vh"]} }}) {
      items {
        slug
        languageCode
      }
    }
  }
`;

// =============================================
//             TOP ANNOUCEMENT BAR QUERY
// =============================================
export const GQL_FETCH_ANNOUNCEMENT_BAR = `
  query {
    eBooks: announcement(id:"GvKiYOMKU4dqSquGU3eBw" preview: ${IS_PREVIEW}) {
      title
      topicCollection {
        items {
          ... on Card {
            link
            preTitle
            title
            bodyCopy {
              json
            }
          }
        }
      }
    }
  }
`;

// -=-=-=-=-=-=-=-=--=-=-=-

export const GQL_FETCH_PAGE_METADATA_ENTRY = gql`
  query ($locale: String!, $slug: String!, $preview: Boolean!) {
    page: pageCollection(
      limit: 1
      where: {
        contentfulMetadata: { tags: { id_contains_some: ["vh"] } }
        languageCode: $locale
        slug: $slug
      }
      preview: $preview
    ) {
      items {
        slug
        languageCode
        seoMetadata {
          title
          description
          previewImage {
            url
          }
          index
          follow
        }
      }
    }

    translatedPages: pageCollection(
      limit: 1
      where: {
        contentfulMetadata: { tags: { id_contains_some: ["vh"] } }
        languageCode: "EN"
        slug: $slug
      }
      preview: $preview
    ) {
      items {
        translationsCollection(limit: 7) {
          items {
            sys {
              id
              firstPublishedAt
            }
            pageName
            slug
            languageCode
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SITEMAPS_DATA = gql`
  query ($preview: Boolean!) {
    postCollection(
      preview: $preview
      where: {
        contentfulMetadata: {
          tags: { id_contains_some: ["vh"], id_contains_none: ["translation"] }
        }
      }
    ) {
      items {
        slug
        languageCode
        sys {
          publishedAt
        }
        translationsCollection {
          items {
            languageCode
            slug
          }
        }
      }
    }
    pageCollection(
      preview: $preview
      where: {
        contentfulMetadata: {
          tags: { id_contains_some: ["vh"], id_contains_none: ["translation"] }
        }
      }
    ) {
      items {
        slug
        languageCode
        sys {
          publishedAt
        }
      }
    }
  }
`;

// =============================================
//              CANONICAL QUERY
// =============================================
export const GQL_FETCH_CANONICAL_DATA = gql`
  query {
    postCollection(
      where: {
        contentfulMetadata: {
          tags: { id_contains_some: ["vh"], id_contains_none: ["translation"] }
        }
      }
    ) {
      items {
        slug
        seoMetadata {
          canonical
        }
        translationsCollection(limit: 7) {
          items {
            languageCode
            slug
            seoMetadata {
              canonical
            }
          }
        }
      }
    }
    pageCollection(
      where: {
        contentfulMetadata: {
          tags: { id_contains_some: ["vh"], id_contains_none: ["translation"] }
        }
      }
    ) {
      items {
        slug
        seoMetadata {
          canonical
        }
      }
    }
  }
`;

export const GQL_FETCH_TOP_NAVIGATION_ENTRY = gql`
  query ($locale: String!, $preview: Boolean!) {
    topNavigation: topNavigationCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vh"] } }, languageCode: $locale }
      preview: $preview
    ) {
      items {
        logo {
          url
          width
          height
          description
        }
        navItemsCollection(order: order_ASC) {
          items {
            sys {
              id
            }
            slug
            pageName
            languageCode
            subPagesCollection(order: order_ASC) {
              items {
                sys {
                  id
                }
                languageCode
                slug
                pageName
                description
              }
            }
          }
        }
      }
    }

    globalTextBlock: globalTextBlocksCollection(
      limit: 1
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }
    ) {
      items {
        allProducts
        pages
        blogArticles
        lastUpdated
        noResultsFor
      }
    }
  }
`;

export const GQL_FETCH_FOOTER_NAVIGATION_ENTRY = gql`
  query ($locale: String!, $preview: Boolean!) {
    footer: footerCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vh"] } }, languageCode: $locale }
      preview: $preview
    ) {
      items {
        logo {
          url
          width
          height
          description
        }
        navItemsCollection(order: order_ASC) {
          items {
            sys {
              id
            }
            slug
            pageName
            subPagesCollection(order: order_ASC) {
              items {
                sys {
                  id
                }
                slug
                pageName
                description
              }
            }
          }
        }
        health
        performance
        tactical
        copyright
        allRightsReserved
        privacyPolicy
        loginToValdHub
      }
    }
  }
`;

export const GQL_FETCH_TOTAL_POST_ENTRY = gql`
  query ($preview: Boolean!) {
    postCollection(
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }
    ) {
      total
    }
  }
`;

export const GQL_FETCH_ANNOUNCEMENT_ENTRIES = gql`
  query ($locale: String!, $preview: Boolean!) {
    eBooks: announcementCollection(
      limit: 1
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }
      preview: $preview
    ) {
      items {
        title
        topicCollection {
          items {
            sys {
              id
            }
            bodyCopy {
              json
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_LIVE_PREVIEW_PAGE_ENTRY = gql`
  query ($entryId: String!) {
    pageCollection(
      where: { sys: { id: $entryId }, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }
      limit: 1
      preview: true
    ) {
      items {
        pageName
        slug
        languageCode
      }
    }
  }
`;

export const QGL_PAGINATED_POST_ENTRIES = gql`
  query (
    $skip: Int!
    $limit: Int
    $categorySlug: String
    $productName: String
    $locale: String!
    $slug: String!
    $preview: Boolean!
  ) {
    page: pageCollection(
      limit: 1
      where: {
        contentfulMetadata: { tags: { id_contains_some: ["vh"] } }
        languageCode: $locale
        slug: $slug
      }
      preview: $preview
    ) {
      items {
        slug
        pageName
        sys {
          publishedAt
          firstPublishedAt
        }
      }
    }

    blogs: postCollection(
      skip: $skip
      limit: $limit
      order: datePublished_DESC
      preview: $preview
      where: {
        languageCode: $locale
        AND: [{ categories: { slug: $categorySlug } }, { categories: { slug: $productName } }]
        contentfulMetadata: { tags: { id_contains_some: ["vh"] } }
      }
    ) {
      total
      items {
        sys {
          id
        }
        slug
        categoriesCollection(limit: 1) {
          items {
            title
            slug
          }
        }
        title
        thumbnail {
          url
          width
          height
          title
          description
          contentType
        }
        datePublished
        body {
          json
        }
        featuredImage {
          url
        }
        excerpt
      }
    }

    globalTextBlock: globalTextBlocksCollection(
      limit: 1
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }
    ) {
      items {
        learnMore
        noDataFound
      }
    }
  }
`;

export const GQL_FETCH_NEWS_AND_RESEARCH_PAGE_TITLE = gql`
  query ($locale: String!, $slug: String!, $preview: Boolean!) {
    page: pageCollection(
      limit: 1
      where: {
        contentfulMetadata: { tags: { id_contains_some: ["vh"] } }
        languageCode: $locale
        slug: $slug
      }
      preview: $preview
    ) {
      items {
        slug
        pageName
        sys {
          publishedAt
          firstPublishedAt
        }
      }
    }

    globalTextBlock: globalTextBlocksCollection(
      limit: 1
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }
    ) {
      items {
        learnMore
        noDataFound
      }
    }
  }
`;

export const GQL_FETCH_NEWS_AND_RESEARCH_PAGE_ENTRIES = gql`
  query (
    $skip: Int
    $limit: Int
    $categorySlug: String
    $excludeId: String
    $locale: String!
    $preview: Boolean!
  ) {
    blogs: postCollection(
      skip: $skip
      limit: $limit
      order: datePublished_DESC
      preview: $preview
      where: {
        languageCode: $locale
        AND: [{ categories: { slug: $categorySlug } }]
        contentfulMetadata: { tags: { id_contains_some: ["vh"] } }
        sys: { id_not: $excludeId }
      }
    ) {
      total
      items {
        sys {
          id
        }
        contentfulMetadata {
          tags {
            name
          }
        }
        slug
        categoriesCollection {
          items {
            title
            slug
          }
        }
        title
        thumbnail {
          url
          width
          height
          title
          description
          contentType
        }
        datePublished
        body {
          json
        }
        featuredImage {
          url
        }
        excerpt
        languageCode
        translationsCollection(limit: 7) {
          items {
            sys {
              id
              firstPublishedAt
            }
            title
            slug
            languageCode
          }
        }
        linkedFrom {
          postCollection(limit: 7) {
            items {
              sys {
                id
              }
              languageCode
              title
              slug
              translationsCollection(limit: 7) {
                items {
                  sys {
                    id
                    firstPublishedAt
                  }
                  languageCode
                  slug
                }
              }
            }
          }
        }
      }
    }

    postCategories: categoryCollection(
      preview: $preview
      order: order_ASC
      where: {
        languageCode: $locale
        belongsTo: "Post"
        contentfulMetadata: { tags: { id_contains_some: ["vh"] } }
      }
    ) {
      items {
        sys {
          id
        }
        title
        slug
      }
    }

    productCategories: categoryCollection(
      preview: $preview
      order: order_ASC
      where: {
        languageCode: $locale
        belongsTo: "Product"
        contentfulMetadata: { tags: { id_contains_some: ["vh"] } }
      }
    ) {
      items {
        sys {
          id
        }
        title
        slug
      }
    }
  }
`;

export const GQL_FETCH_LATEST_BLOG_ENTRIES = gql`
  query ($locale: String!, $preview: Boolean!) {
    latestBlog: postCollection(
      limit: 1
      preview: $preview
      order: datePublished_DESC
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }
    ) {
      items {
        sys {
          id
        }
        slug
        categoriesCollection(limit: 1) {
          items {
            title
            slug
            title
          }
        }
        title
        thumbnail {
          url
          width
          height
          title
          description
          contentType
        }
        datePublished
        excerpt
        languageCode
        translationsCollection(limit: 7) {
          items {
            sys {
              id
              firstPublishedAt
            }
            title
            slug
            languageCode
          }
        }
        linkedFrom {
          postCollection(limit: 7) {
            items {
              sys {
                id
              }
              languageCode
              title
              slug
              translationsCollection(limit: 7) {
                items {
                  sys {
                    id
                    firstPublishedAt
                  }
                  languageCode
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;
