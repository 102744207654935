import { InspectorModeTags } from '@contentful/live-preview/dist/inspectorMode/types';

export type InspectorPropTypes = InspectorModeTags | Record<string, never>;

export type SysTypes = {
  id: string;
};

export type ButtonTypes = {
  sys: SysTypes;
  url: string;
  text: string;
  type: string;
  target: string;
};

export type ServiceTypes = {
  link: string;
  sys: SysTypes;
  bodyCopy: BodyCopyTypes;
  mediaPrimary: MediaDataTypes;
  mediaSecondary: MediaDataTypes;
  title: string;
  slug: string;
};

export type FeatureTypes = {
  sys: SysTypes;
  bodyCopy: BodyCopyTypes;
  title: string;
  mediaPrimary: MediaDataTypes;
};

/* eslint-disable no-unused-vars */
export enum GQL_PAGE_ID_TYPES {
  HOME_LANDING_PAGE = '3oI66x0FLi0pgAB08H6Xcw',
  SOLUTIONS_LANDING_PAGE = '5wkdVJLZdz4djSzVdgZY4k',
  CLINICIANS_LANDING_PAGE = '6CkZxgAv4c5ziWcXveqepb',
  PATHOLOGIES_LANDING_PAGE = '7JrB8B4yqDxLb7fFkGcELt',
  PRODUCTS_LANDING_PAGE = '166QgRPjxBUV6vPtWoqcth',
  NEWS_AND_RESEARCH_LANDING_PAGE = '4YuVlBij70lkl4IduGW6Ip',
  CONTACT_LANDING_PAGE = '24YT1SA9C584fs8Jy6Fj9n',
  PRIVATE_POLICY_LANDING_PAGE = 'jV92zFcwSLMxTTmeJBHJ5',

  // PRODUCT LANDING PAGES
  DYNAMO_LANDING_PAGE = '5hRVNrgLowsa9faboZ8Y3U',
  FORCEFRAME_LANDING_PAGE = '1VWIckmMFSicLSaQI1j5EA',
  FORCEDECKS_LANDING_PAGE = '5hWleLfjGGc0j5HakYLe3p',
  HUMANTRAK_LANDING_PAGE = '6yf75lws88nihmGUELEcqH',
  NORDBORD_LANDING_PAGE = '6qeBRXRi6D8yevxjZsEEoi',
  SMARTSPEED_LANDING_PAGE = '53i7HkZfaBUE1Rf9rFUQXb',
  VALDHUB_LANDING_PAGE = '1gYX4jaSC5XAcLnbk0VLah',
  MOVEHEALTH_LANDING_PAGE = '7LjPuz5vAkFBoRpkBUFQBU',
}
/* eslint-enable no-unused-vars */

export type MediaDataTypes = {
  url: string;
  description: string;
  height: number;
  width: number;
  contentType: string;
};

export type BlogSummaryTypes = {
  translationsCollection: {
    items: {
      sys: SysTypes;
      slug: string;
      languageCode: string;
    }[];
  };
  languageCode: string;
  sys: SysTypes;
  total: string;
  author: string;
  title: string;
  slug: string;
  datePublished: string;
  thumbnail: MediaDataTypes;
  excerpt: string;
  categoriesCollection: CategoriesCollectionTypes;
  globalTextBlock: {
    items: [
      {
        learnMore: string;
      },
    ];
  };
};

export type categoriesTypes = {
  title: string;
  slug: string;
  name: CategoryTypes;
  sys: SysTypes;
};

export type CategoryTypes =
  | 'All'
  | 'Case Studies'
  | 'News & Announcements'
  | 'Guest Spot'
  | 'Guides'
  | 'Whitepapers'
  | 'Clinical Studies';

export type CategoriesCollectionTypes = {
  items: categoriesTypes[];
};

export type ContentTypes = {
  data: object;
  marks: any[];
  value: string;
  nodeType: string;
};

export type BodyCopyJsonTypes = {
  data: object;
  content: {
    data: object;
    content: ContentTypes[];
    nodeType: string;
  }[];
  nodeType: string;
};

export type BodyCopyLinksTypes = {
  assets: {
    block: any[];
  };
  entries: {
    block: any[];
    inline: any[];
  };
};

export type BodyCopyTypes = {
  link: string;
  json: BodyCopyJsonTypes;
  links: BodyCopyLinksTypes;
};

export interface ButtonTopicItemTypes {
  sys: SysTypes;
  url: string;
  text: string;
  type: string;
  target: string;
}

export interface GridTopicItemTypes {
  sys: SysTypes;
  preTitle: string;
  title: string;
  mediaPrimary: MediaDataTypes;
  bodyCopy: BodyCopyTypes;
}

// =============================================
//          META DATA QUERY RESPONSE TYPES
// =============================================
export type LandingPageMetaDataQueryResponseTypes = {
  page: {
    seoMetadata: {
      canonical: any;
      title: string;
      description: string;
      previewImage: {
        url: string;
      };
      index: string;
      follow: string;
    };
  };
};

export type PageMetadataQueryResponseTypes = {
  page: {
    items: [
      {
        languageCode: string;
        seoMetadata: {
          canonical: any;
          title: string;
          description: string;
          previewImage: {
            url: string;
          };
          index: string;
          follow: string;
        };
      },
    ];
  };
  translatedPages: {
    items: [
      {
        translationsCollection: {
          items: {
            languageCode: string;
            slug: string;
          };
        };
      },
    ];
  };
};

export type PostMetadataQueryResponseTypes = {
  post: {
    items: [
      {
        languageCode: string;
        slug: string;
        seoMetadata: {
          canonical: any;
          title: string;
          description: string;
          previewImage: {
            url: string;
          };
          index: string;
          follow: string;
        };
        translationsCollection: {
          items: {
            languageCode: string;
            slug: string;
          };
        };
        linkedFrom: {
          translationsCollection: {
            items: {
              sys: SysTypes;
              slug: string;
              languageCode: string;
            }[];
          };
          postCollection: PageMetadataQueryResponseTypes;
        };
      },
    ];
  };
};
